import Header from "./react-components/header-details/header";
import Main from "./react-components/main/main";
import Footer from "./react-components/footer/footer";
import {BrowserRouter, Route , Routes} from "react-router-dom";
import Projects from "./react-components/Projects/projects";
import Blog from "./react-components/Blog/blog";
import CV from "./react-components/CV/cv";
import Post from "./react-components/Blog/post"
import { useState } from "react";
import axios from "axios";


function App() {

  let [data,setData] = useState ("");
  let [german,setGerman] = useState(true);
  let [englisch,setEnglisch] = useState(false);


 async function getPost(event){
    let post = event.target.value 
    let apiurl = process.env.REACT_APP_API_URL
    console.log(apiurl);
    try{
    await axios.post(apiurl+"/blogposts",{responseType:"json",data:post}).then((response)=>{
      setData(response.data);
      console.log(response.data);
    });
    }catch(err){
      console.log(err)
    }
  }
  function setLangGerman(){
    setGerman(true);
    setEnglisch(false);
  }
  function setLangEnglisch(){
    setGerman(false);
    setEnglisch(true);
  }

  return (
<BrowserRouter>
    <Header setGerman={setLangGerman} setEnglisch={setLangEnglisch} />
    <Routes>
    <Route exact path="/" element={<Main englisch={englisch} german={german} />} />
    <Route path="/Projects" element={<Projects englisch={englisch} german={german}/>}/>
    <Route path="/Blog" element={<Blog englisch={englisch} german={german} getPost={getPost}/>}/>
    <Route path="CV" element={<CV englisch={englisch} german={german} />}/>
    <Route path={"/post"} element={<Post englisch={englisch} german={german} data={data}/>}/>
  </Routes>
    <Footer/>
</BrowserRouter>
  );
}

export default App;
