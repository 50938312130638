import React from "react";
import linkedIn from "../../assets/img/linkedIn.png";
import github from "../../assets/img/github.png";
import fiverr from "../../assets/img/fiverr.png";
import copyright from "../../assets/img/copyright.png"

function Footer (){
    let date = new Date();
    let year = date.getFullYear();

return(
<>  
    <div className="Footer-Container">
            <p><img className="Logo" src={copyright} alt="copyright Logo" /> Robert Saliger {year}</p>
        <ul className="Socials">
            <a href="https://www.linkedin.com/in/robert-saliger-646047174/"><li><img className="Logo" src={linkedIn} alt="LinkedIn"/></li></a>
            <a href="https://github.com/Robz187"><li><img className="Logo" src={github} alt="Github"/></li></a>
            <a href="https://www.fiverr.com/robert_saliger?public_mode=true"><li><img className="Logo" alt="Fiverr" src={fiverr}/></li></a>
        </ul>
    </div>
</>
)
}

export default Footer;