import React, { useEffect, useState } from "react";
import {Link} from "react-router-dom";

function Header(props){

    let [currLang,setCurrLang] = useState("Deutsch");
    let [gerActiv,setGerActiv] = useState("active");
    let [engActiv,setEngActiv] = useState("");
    let [cv,setCV] = useState("Lebenslauf");
    let [projects,setProjects] = useState("Projekte");
    let [lang,setLang] = useState("Sprache: ")

    useEffect(()=>{
        if(currLang === "Deutsch"){
            props.setGerman();
        }
        if(currLang === "Englisch"){
            props.setEnglisch();
        }
        // eslint-disable-next-line
    },[currLang]);
return (
<div className="Header-Container">
<h1 className="Title">Robert Saliger</h1> 
<ul className="Navbar">
  <div class="sl-nav">
    {lang}
    <ul>
      <li><b>{currLang}</b> <i class="fa fa-angle-down" aria-hidden="true"></i>
        <div class="triangle"></div>
        <ul>
          <li><i class="sl-flag flag-de"><div id="germany"></div></i> <span class={gerActiv} 
          onClick={() => { 
            setCurrLang("Deutsch");
            setGerActiv("active");
            setEngActiv("inactiv");
            setCV("Lebenslauf");
            setProjects("Projekte");
            setLang("Sprache: ");
            }} 
          >Deutsch</span></li>
          <li><i class="sl-flag flag-usa"><div id="germany"></div></i> <span className={engActiv} 
          onClick={()=> {
            setCurrLang("Englisch");
            setGerActiv("inactiv");
            setEngActiv("active");
            setCV("CV");
            setProjects("Projects");
            setLang("Language: ");
            }} >Englisch</span></li>
        </ul>
      </li>
    </ul>
  </div>
    <Link className="Navbar-Item-Link" style={{textDecoration:"none"}} to="/"><li className="Navbar-Item">Home</li></Link>
    <Link className="Navbar-Item-Link" style={{textDecoration:"none"}} to="/Projects"><li className="Navbar-Item">{projects}</li></Link>
    <Link className="Navbar-Item-Link" style={{textDecoration:"none"}} to="/CV"><li className="Navbar-Item">{cv}</li></Link>
    <Link className="Navbar-Item-Link" style={{textDecoration:"none"}} to="/Blog"><li className="Navbar-Item">Blog</li></Link>
</ul>

</div>


);
}
export default Header;