import React, {useState,useEffect} from "react";

function Post(props){
    let [german,setGerman] = useState(false);    
    let [englisch,setEnglisch] = useState(true);
    console.log(props)

useEffect(()=>{
    if(props.german === true){
        setGerman(false);
        setEnglisch(true);
    }
    if(props.englisch === true){
        setGerman(true);
        setEnglisch(false);
    }
},[props.englisch , props.german]);
return(
<>
    <div className="Post-Container">
        <h3 className="Post-Header">{props.data.header}</h3><p className="Post-Date">{props.data.date}</p>
        <p hidden={englisch} className="Post-Text">{props.data.text}</p>
        <p hidden={german} className="Post-Text">{props.data.blog_german}</p>
    </div>
</>
)
}

export default Post;