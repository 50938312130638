import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios"

 
function Blog (props){

   let [blog,setBlog] = useState([]);
   let apiurl = process.env.REACT_APP_API_URL

function getBlogPosts(){
   axios.get(apiurl+"/blogposts",{responseType:"json"}).then((response)=>{
   setBlog(response.data)});
}
useEffect(()=>{
   getBlogPosts();
   //eslint-disable-next-line
},[]);
let blogPosts = blog.map((data,i)=>{
   return(
      <Link className="Blog-Link" key={i} to="/post"><li className="Blog-Item" value={data.id} onClick={props.getPost}>{data.header}</li><p className="Blog-Date">{data.blog_date}</p></Link>
   );
});

return(
<>
   <div className="Blog-Container">
      <ul>
         {blogPosts}
      </ul>
   </div>

</>
)
}

export default Blog;