import React , { useState , useEffect} from "react";

function CV (props){
    let [german,setGerman] = useState(false);    
    let [englisch,setEnglisch] = useState(true);


useEffect(()=>{
    if(props.german === true){
        setGerman(false);
        setEnglisch(true);
    }
    if(props.englisch === true){
        setGerman(true);
        setEnglisch(false);
    }
},[props.englisch , props.german]);
return(
   <div className="CV-Container">
        <div className="Container-Top">
            <div className="CV-Header">
                <h2 className="CV-Title">Robert Saliger</h2>
                <h3 hidden={englisch} className="CV-Title-Title">Web Developer</h3>
                <h3 hidden={german} className="CV-Title-Title">Web Entwickler</h3>
            </div>
        </div>

        <div className="Container-Left">
        <div className="CV-Details">
            <h4 hidden={englisch} className="CV-Details-Title">Details</h4>
            <h4 hidden={german} className="CV-Details-Title">Kontakt</h4>
            <hr/>
            <p>Email : saliger.robert@gmail.com</p>
        </div>
        <div className="CV-Skills">
            <h4 className="CV-Title">Skills</h4>
            <hr/>
            <p className="CV-Skill">HTML</p>
            <p className="CV-Rating">★★★★★</p>
            <p className="CV-Skill">CSS</p>
            <p className="CV-Rating">★★★☆☆</p>
            <p className="CV-Skill">Javascript</p>
            <p className="CV-Rating">★★★★☆</p>
            <p className="CV-Skill">React</p>
            <p className="CV-Rating">★★★★☆</p>
        </div>
        <div className="CV-Skills">
            <h4 hidden={englisch} className="CV-Title">Languages</h4>
            <h4 hidden={german} className="CV-Title">Sprachen</h4>
            <hr/>
            <p className="CV-Skill">Deutsch</p>
            <p className="CV-Rating">★★★★☆</p>
            <p className="CV-Skill">Englisch</p>
            <p className="CV-Rating">★★★★☆</p>
        </div>
        </div>
        <div className="Container-Middle">
            <div className="Profile">
                <h4 hidden={englisch} className="Profile-Title">Profile</h4>
                <h4 className="Profile-Title">Kurzprofil</h4>
                <hr/>
                <p hidden={englisch} className="Profile-Text">As a Junior Web Developer, my journey into coding began at the age of 17, although I've only recently committed myself to pursuing it full-time 
                alongside my current role. Self-taught through Udemy courses, I am driven by an insatiable desire to continually improve. My skillset includes HTML, CSS, 
                JavaScript, Node.js, React, PostgreSQL, Web3, and DApps, but I specialize in React, CSS, and PostgreSQL, leveraging Express for my web applications. 
                My goal is to push the boundaries of web development and deliver innovative solutions with every project.</p>
                <p hidden={german} className="Profile-Text">Als Junior Webentwickler begann meine Reise in die Welt des Codens im Alter von 17 Jahren, 
                jedoch habe ich mich erst kürzlich dazu entschlossen, mich neben meiner aktuellen Tätigkeit voll und ganz darauf zu konzentrieren. 
                Ich habe mich autodidaktisch durch Udemy-Kurse weitergebildet und bin von einem unersättlichen Wunsch getrieben, mich kontinuierlich zu verbessern. 
                Mein Fähigkeiten umfassen HTML, CSS, JavaScript, Node.js, React, PostgreSQL, Web3 und DApps, wobei ich mich auf React, CSS und PostgreSQL 
                spezialisiert habe und Express für meine Webanwendungen nutze. Mein Ziel ist es, die Grenzen der Webentwicklung zu erweitern und mit jedem Projekt 
                innovative Lösungen zu liefern.</p>
            </div>
            <div className="History">
                <h4 hidden={englisch} className="History-Title">Employment History</h4>
                <h4 hidden={german} className="History-Title">Karriere</h4>
                <hr/>
                <div className="History-Text">
                    <div className="History-Text-Title"><h4 className="History-Text-Title" >Callcenter Agent Inbound , Raiffeisen Beratung Direkt </h4></div> 
                    <div hidden={englisch}><p  className="History-Subtitle City">Vienna,Austria</p></div>
                    <div hidden={german}><p  className="History-Subtitle City">Wien,Österreich</p></div>
                    <p hidden={englisch} className="History-Subtitle">Jan. 2020 - Today</p>
                    <p hidden={german} className="History-Subtitle">Jan. 2020 - Heute</p>
                    <p  hidden={englisch} className="History-Text">My responsibilities involve:<br/>
                    - Handling email inquiries from corporate entities and private clients.<br/>
                    - Providing assistance to corporate clients with Business Online Banking.<br/>
                    - Supporting private clients with Online Banking queries.<br/>
                    - Managing appointment scheduling and coordinating callback requests, as well as offering hotline support to various bank branches across Austria.</p>
                    <p hidden={german} className="History-Text">Meine Aufgaben umfassen:<br/>
                    - Bearbeitung von Support-Anfragen von Firmenkunden und Privatkunden sowohl telefonisch als auch per Mail.<br/>
                    - Unterstützung von Firmenkunden beim Business Online Banking.<br/>
                    - Unterstützung von Privatkunden bei Online-Banking-Anfragen.<br/>
                    - Verwaltung von Terminplanung und Koordination von Rückrufanfragen sowie Bereitstellung von Hotline-Unterstützung für verschiedene Bankfilialen in ganz Österreich.</p>

                    <h4 className="History-Text-Title">Kaufm. Assitenz Informationstechnik, BBSIV Friedrich List</h4>  
                    <div hidden={englisch}><p  className="History-Subtitle City">Halle/Saale,Germany</p></div>
                    <div hidden={german}><p hidden={german} className="History-Subtitle City">Halle/Saale,Deutschland</p></div>
                    <p className="History-Subtitle">Sept. 2016 - Aug. 2018</p>
                    <p hidden={englisch} className="History-Text">During my academic training, I gained my initial exposure to programming and standard back-office tasks.</p>
                    <p hidden={german} className="History-Text">- Während meiner akademischen Ausbildung habe ich meine ersten Erfahrungen mit Programmierung und Standardaufgaben im Backoffice gesammelt.</p>
                </div>
            </div>
            <div className="Education">
                <h4 className="Education-Title">Education</h4>
                <hr/>
                <div className="Education-Text">
                    <h4 className="Education-Text-Title">The Fullstack Web Development Course by Angela Yu</h4>
                    <div hidden={englisch}><p className="Education-Subtitle City">Vienna</p></div>
                    <div hidden={german}><p className="Education-Subtitle City">Wien</p></div>    
                    <p className="Education-Subtitle">Jun. 2023 - Jan. 2024</p>
                    <h4 className="Education-Text-Title">Realschule am Fliederweg</h4> 
                    <div hidden={englisch}><p className="Education-Subtitle City">Halle/Saale, Germany</p></div>
                    <div hidden={german}><p className="Education-Subtitle City">Halle/Saale, Deutschland</p></div>
                    <p className="Education-Subtitle">2010-2016</p>
                    <h4 className="Education-Text-Title">Grundschule am Rosengarten</h4> 
                    <div hidden={englisch}><p className="Education-Subtitle City">Halle/Saale, Germany</p></div>
                    <div hidden={german}><p className="Education-Subtitle City">Halle/Saale, Deutschland</p></div>
                    <p className="Education-Subtitle">2006-2010</p>
                </div>
            </div>
        </div>
   </div> 
)   
}

export default CV;