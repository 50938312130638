import React, { useEffect, useState } from "react";
import ProfilePicture from "../../assets/img/profilepic.jpeg"

function Main(props){
let [german,setGerman] = useState(false);    
let [englisch,setEnglisch] = useState(true);


useEffect(()=>{
    if(props.german === true){
        setGerman(true);
        setEnglisch(false);
    }
    else if(props.englisch === true){
        setGerman(false);
        setEnglisch(true);
    }
},[props.englisch , props.german]);
return(
<div className="Content-Container">
    <div className="Img-Container">
         <img className="Main-Img" src={ProfilePicture} alt="Robert Saliger"/>   
    </div>
    <div className="Home-Container">
        <section hidden={!englisch} className="Text-Englisch">   
            <p className="Homepage-Text"><span className="Highlight"> 
            Passionate web developer</span><br/> dedicated to crafting exceptional websites with <br/>  
            <span className="Highlight"> joy and precision.</span> </p>
        </section>
        <section hidden={!german} className="Text-Deutsch">   
            <p className="Homepage-Text"><span className="Highlight"> 
            Webentwickler aus Leidenschaft</span><br/> spezialisiert zum erstellen einzigartiger Websites  <br/> 
            <span className="Highlight"> mit Spaß und Präzison.</span> </p>
        </section>
    </div>
</div>
)

}

export default Main;