import React, { useEffect, useState } from "react";
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";
import Button from 'react-bootstrap/Button';
import CarouselCaption from "react-bootstrap/esm/CarouselCaption";

function Projects (props){
    let apiUrl = process.env.REACT_APP_API_URL;
    let [projects,setProjects] = useState([]);
    let [german,setGerman] = useState(false);    
    let [englisch,setEnglisch] = useState(true);


useEffect(()=>{
    if(props.german === true){
        setGerman(false);
        setEnglisch(true);
    }
    if(props.englisch === true){
        setGerman(true);
        setEnglisch(false);
    }
},[props.englisch , props.german]);

    function fetchData(){
        axios.get(apiUrl+"/projects").then((response)=>{
            console.log(response);
            setProjects(response.data.data);
        });
    }
    useEffect(()=>{
        fetchData();
           //eslint-disable-next-line
    },[]);

    let projectMap = projects.map((data,i)=>{

        let projectLink = data.project_link.startsWith('http') ? data.project_link : `http://${data.project_link}`;

            return(
                <Carousel.Item key={i}>
                    <img className="Projects-Item-Image" height="50" src={data.filepath} alt="Website Foto"></img>
                        <CarouselCaption >
                            <div className="Projects-Background">
                                <h4 className="Projects-Item-Header">{data.project_name}</h4>
                                <p hidden={englisch} className="Projects-Item-Text">{data.project_text}</p>
                                <p hidden={german} className="Projects-Item-Text">{data.project_text_german}</p>
                            </div>
                            <Button className="Projects-Item-Link" target="_blank" rel="noopener noreferrer" href={projectLink} variant="info">To the website</Button>    
                        </CarouselCaption>                           
                        </Carousel.Item>
        );
    })

return(
<>
    <h3 className="Projects-Header">My Projects</h3>
    <Carousel>
    {projectMap}
    </Carousel>
</>
)
}

export default Projects;